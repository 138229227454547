/*
 * Copyright Devlin Richberger - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Devlin Richberger <lord_fronic@yahoo.com>, February 2023
 */

import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import '../stylesheets/Home.css';
import NavigationBar from './NavigationBar';
import banner from '../images/Spring.png'

class Home extends Component {
    render() {
        return (
            <div>
                <NavigationBar/>
                <Container>
                    <Row>
                        <Col xs="3"></Col>
                        <Col xs="auto">
                            <div className="banner"><img src={banner} alt="banner"
                                                         className="img-fluid mx-auto d-block"/>
                            </div>
                        </Col>
                        <Col xs="3"></Col>
                    </Row>
                </Container>
            </div>
        )
            ;
    }
}

export default Home;