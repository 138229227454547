/*
 * Copyright Devlin Richberger - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Devlin Richberger <lord_fronic@yahoo.com>, February 2023
 */

import React, {Component} from 'react';
import '../stylesheets/Vellas.css';
import NavigationBar from './NavigationBar';
import {Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row} from 'reactstrap';
import earthworm from '../images/vellas/mkr/earthworm.jpg'
import morning_star from '../images/vellas/mkr/morning_star.jpg'
import human from '../images/vellas/mkr/human.jpg'
import idontacceptit from '../images/vellas/fw/idontacceptit.jpg'

class Books extends Component {
    render() {
        return (
            <div>
                <NavigationBar/>
                <Container>
                    <Row>
                        <Col xs="3"></Col>
                        <Col xs="auto">
                            <Container>
                                <Row>
                                    UNDER CONSTRUCTION
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                    <Col sm="12" md="6" lg="3" xl="3" xxl="2">
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="3"></Col>
                    </Row>
                </Container>
            </div>
        )
            ;
    }
}

export default Books;