/*
 * Copyright Devlin Richberger - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Devlin Richberger <lord_fronic@yahoo.com>, February 2023
 */

import React, {Component} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import {Link} from 'react-router-dom';
import logo from '../images/Publisher Logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';;

export default class NavigationBar extends Component {

    state = {
        collapsed: true
    };

    toggle = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
    };

    render() {
        const {collapsed} = this.state;
        return <Navbar color="light" light expand="md" container="fluid" className="justify-content-center">
            <NavbarToggler onClick={this.toggle} className="me-auto"/>
            <NavbarBrand tag={Link} to="/"><img src={logo} className="App-logo" alt="logo" /></NavbarBrand>
            <Collapse isOpen={!collapsed} navbar>
                <Nav className='m-auto' navbar>
                    <NavItem><NavLink href="/" className="mkr-nav-link">About</NavLink></NavItem>
                    <NavItem><NavLink href="/#/books" className="mkr-nav-link">Featured Books</NavLink></NavItem>
                    <NavItem><NavLink href="/#/shop" className="mkr-nav-link">Shop</NavLink></NavItem>
                    <NavItem><NavLink href="/#/contact" className="mkr-nav-link">Contact Us</NavLink></NavItem>
                </Nav>
            </Collapse>
        </Navbar>;
    }
}