/*
 * Copyright Devlin Richberger - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Devlin Richberger <lord_fronic@yahoo.com>, February 2023
 */

import React, {Component} from 'react';
import '../stylesheets/Vellas.css';
import NavigationBar from './NavigationBar';
import {Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row} from 'reactstrap';
import idontacceptit from '../images/vellas/fw/idontacceptit.jpg'

class Vellas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            books: [], // Store the fetched book metadata here
        };
    }

    componentDidMount() {
        this.fetchBookMetadata();
    }

    async fetchBookMetadata() {
        await fetch('/api/v1/vellas') // Replace with the actual API URL
            .then((response) => response.json())
            .then((data) => {
                this.setState({books: data});
            })
            .catch((error) => {
                console.error('Error fetching book metadata:', error);
            });
    };

    render() {
        const {books} = this.state;

        return (
            <div>
                <NavigationBar/>
                <Container>
                    <Row>
                        <Col xs="3"></Col>
                        <Col xs="auto">
                            <h1 className="text-center py-2">MK Richberger</h1>
                            <Container>
                                <Row>
                                    {books.map((book) => (
                                        <Col key={book.asin} xs="1" sm="12" md="6" lg="3" xl="3" xxl="3">
                                            <Card
                                                color="dark"
                                                inverse
                                            >
                                                <img style={{
                                                    objectFit: 'cover',
                                                    objectPosition: '50% 16.5%',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                                     alt="{book.title}"
                                                     src={book.imageUrl}
                                                />
                                                <CardBody>
                                                    <CardSubtitle
                                                        className="mb-2"
                                                        tag="h6"
                                                        style={{color: '#6c757d'}}
                                                    >
                                                        {book.tags.join(" / ")}
                                                    </CardSubtitle>
                                                    <CardText style={{whiteSpace: 'pre-line'}}>
                                                        {book.description.replaceAll("<br>", "\n\r")}
                                                    </CardText>
                                                    <a href={"http://www.amazon.com/dp/" + book.asin}
                                                       target="_blank" className="btn btn-secondary">
                                                        Read It Here!
                                                    </a>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                            <h1 className="text-center py-2">Faye Williams</h1>
                            <Container>
                                <Row>
                                    {/*{[1,2,3,4,5,6,7,8,9].map((book) => (*/}
                                    <Col xs="12" sm="12" md="6" lg="5" xl="4" xxl="3">
                                        <Card
                                            color="dark"
                                            inverse
                                        >
                                            <img
                                                alt="IDontAcceptIt"
                                                src={idontacceptit}
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    I Don't Accept It
                                                </CardTitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{color: '#6c757d'}}
                                                >
                                                    Romance / Thriller
                                                </CardSubtitle>
                                                <CardText>
                                                    "Jake, are you listening to me? I am breaking up with you." I spat
                                                    out. I can't stand it when he ignores me. "You can't pretend this
                                                    isn't happening Jake. Talk to me, please" He looked up at me,
                                                    studying my face for a moment. I don't know what he was looking to
                                                    find. This was my fourth attempt to break up with him. This time I
                                                    would stand my ground. This time I wouldn't let him guilt me into
                                                    staying. The only question is, am I prepared for what happens after
                                                    I leave him?
                                                </CardText>
                                                <a href="https://www.amazon.com/I-Dont-Accept-It/dp/B0BRX1KF6P/?fbclid=IwAR23Z1nR3mnf-e3wdnjn6f_DRkFJ7vVmWKNqkyGsUq5Tfqki0WyR5bSB5hY"
                                                   target="_blank" className="btn btn-secondary">
                                                    Read It Here!
                                                </a>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    {/*))}*/}
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="3"></Col>
                    </Row>
                </Container>
            </div>
        )
            ;
    }
}

export default Vellas;