/*
 * Copyright Devlin Richberger - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Devlin Richberger <lord_fronic@yahoo.com>, February 2023
 */

import React, {Component} from 'react';
import '../stylesheets/App.css';
import Home from './Home';
import Vellas from './Vellas';
import Books from './Books';
import Shop from './Shop';
import Contact from './Contact';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';

class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route exact path='/' element={<Home/>}/>
                    <Route exact path='/books' element={<Books/>}/>
                    <Route exact path='/vellas' element={<Vellas/>}/>
                    <Route exact path='/shop' element={<Shop/>}/>
                    <Route exact path='/contact' element={<Contact/>}/>
                </Routes>
            </Router>
        )
    }
}

export default App;
