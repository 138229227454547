/*
 * Copyright Devlin Richberger - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Devlin Richberger <lord_fronic@yahoo.com>, February 2023
 */

import React, {Component} from 'react';
import '../stylesheets/Vellas.css';
import NavigationBar from './NavigationBar';
import {
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    Col,
    Container,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row
} from 'reactstrap';

class Books extends Component {

    constructor(props) {
        super(props);
        this.state = {
            books: [], // Store the fetched book metadata here
            dropDownOpen:false,
            filterOption:"All"
        };
    }

    componentDidMount() {
        this.fetchBookMetadata();
    }

    async fetchBookMetadata() {
        await fetch('/api/v1/books')
            .then((response) => response.json())
            .then((data) => {
                this.setState({books: data});
            })
            .catch((error) => {
                console.error('Error fetching book metadata:', error);
            });
    };

    render() {
        const {books} = this.state;
        const {dropDownOpen} = this.state;
        const {filterOption} = this.state;


        return (
            <div>
                <NavigationBar/>
                <Container>
                    <Row>
                        <Col xs="3"></Col>
                        <Col xs="auto">
                            <Dropdown isOpen={dropDownOpen} toggle={() => this.setState({ dropDownOpen: !dropDownOpen })} >
                                <DropdownToggle caret>Genre</DropdownToggle>
                                <DropdownMenu dark={true}>
                                    <DropdownItem onClick={() => this.setState({ filterOption: "All" })}>All</DropdownItem>
                                    <DropdownItem divider />
                                    {
                                        Array.from(new Set(books.flatMap(book => book.tags))).sort((a,b)=>a.localeCompare(b)).map(tag=>(

                                        <DropdownItem onClick={() => this.setState({ filterOption: tag })}>{tag}</DropdownItem>
                                        ))
                                    }
                                </DropdownMenu>
                            </Dropdown>
                            <h1 className="text-center py-2">MK Richberger</h1>
                            <Container>
                                <Row>
                                    {books.map((book) => (filterOption==="All" ?
                                        <Col key={book.asin} xs="1" sm="12" md="6" lg="3" xl="3" xxl="3">
                                            <Card
                                                color="dark"
                                                inverse
                                            >
                                                <img style={{
                                                    objectFit: 'cover',
                                                    objectPosition: '50% 16.5%',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                                     alt="{book.title}"
                                                     src={book.imageUrl}
                                                />
                                                <CardBody>
                                                    <CardSubtitle
                                                        className="mb-2"
                                                        tag="h6"
                                                        style={{color: '#6c757d'}}
                                                    >
                                                        {book.tags.join(" / ")}
                                                    </CardSubtitle>
                                                    <CardText style={{whiteSpace: 'pre-line'}}>
                                                        {book.description.replaceAll("<br>", "\n\r")}
                                                    </CardText>
                                                    <a href={"http://www.amazon.com/dp/" + book.asin}
                                                       target="_blank" className="btn btn-secondary">
                                                        Read It Here!
                                                    </a>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                            :
                                        book.tags.includes(filterOption) &&
                                                <Col key={book.asin} xs="1" sm="12" md="6" lg="3" xl="3" xxl="3">
                                                    <Card
                                                        color="dark"
                                                        inverse
                                                    >
                                                        <img style={{
                                                            objectFit: 'cover',
                                                            objectPosition: '50% 16.5%',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                             alt="{book.title}"
                                                             src={book.imageUrl}
                                                        />
                                                        <CardBody>
                                                            <CardSubtitle
                                                                className="mb-2"
                                                                tag="h6"
                                                                style={{color: '#6c757d'}}
                                                            >
                                                                {book.tags.join(" / ")}
                                                            </CardSubtitle>
                                                            <CardText style={{whiteSpace: 'pre-line'}}>
                                                                {book.description.replaceAll("<br>", "\n\r")}
                                                            </CardText>
                                                            <a href={"http://www.amazon.com/dp/" + book.asin}
                                                               target="_blank" className="btn btn-secondary">
                                                                Read It Here!
                                                            </a>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Col>
                        <Col xs="3"></Col>
                    </Row>
                </Container>
            </div>
        )
            ;
    }
}

export default Books;